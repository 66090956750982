<div class="go-high-level-wrapper">
  <div *ngIf="integration?.accessToken" class="go-high-level-wrapper__header">
    <h3>Smart Moving provider key</h3>

    <mat-slide-toggle class="example-margin" color="primary" (change)="toggleEnable($event.checked)"
      [(ngModel)]="integration.enabled" [disabled]="!integration?.accessToken">
      <strong>Enabled</strong>
    </mat-slide-toggle>
  </div>

  <mat-accordion *ngIf="integration.accessToken">
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ integration.accessToken }} </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-action-row *ngIf="!isConfirmDelete">
        <button mat-icon-button color="warn" (click)="confirmDelete()">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-action-row>
      <mat-action-row *ngIf="isConfirmDelete">
        <span class="confirm">Are you sure?</span>
        <button mat-button color="primary" (click)="deleteApiKey()">Yes</button>
        <button mat-button (click)="cancelDelete()">No</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>

  <div *ngIf="!integration.accessToken">
    <h3 style="margin-top: 20px">Add new Provider Key</h3>
    <p>
      Your Provider key can be found by navigating to Settings -> Sales -> Lead Providers -> Your Website. Once at the
      your Website select option, View Instructions.
    </p>

    <mat-form-field appearance="fill">
      <mat-label>Enter your provider key</mat-label>
      <input matInput [(ngModel)]="newapiKey" />
    </mat-form-field>
    <mat-checkbox [checked]="createCustomerWithPhone" (click)="toggleCreateLeads()">Create leads only with phone
    </mat-checkbox>
    <div>
      <button mat-raised-button color="primary" (click)="addCreds()" [disabled]="isLodaing">Add Provider Key</button>
    </div>
  </div>
</div>