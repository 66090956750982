import {Component} from "@angular/core";

@Component({
  selector: "yelp-blog",
  templateUrl: "yelp-bot-blog.component.html",
  styleUrls: ["yelp-bot-blog.component.scss"]
})
export class YelpBotBlogComponent {
  constructor() {}
}
