import {Component, Input} from "@angular/core";

@Component({
  selector: "app-question",
  templateUrl: "question.component.html",
  styleUrls: ["question.component.scss"]
})
export class Question {
  @Input()
  Answer: string = "";
  @Input()
  Question: string = "";
  constructor() {}
}
