<div class="zapier-wrapper">
  <div *ngIf="integration?.apiKey" class="hubspot-wrapper__header">
    <h3>ZapierAccess token</h3>

    <mat-slide-toggle class="example-margin" color="primary" (change)="toggleEnable($event.checked)"
      [(ngModel)]="integration.enabled" [disabled]="!integration?.apiKey">
      <strong>Enabled</strong>
    </mat-slide-toggle>
  </div>

  <mat-accordion *ngIf="integration.apiKey">
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ integration.apiKey }} </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-action-row *ngIf="!isConfirmDelete">
        <button mat-icon-button color="warn" (click)="confirmDelete()">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-action-row>
      <mat-action-row *ngIf="isConfirmDelete">
        <span class="confirm">Are you sure?</span>
        <button mat-button color="primary" (click)="deleteAcessToken()">Yes</button>
        <button mat-button (click)="cancelDelete()">No</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>

  <div *ngIf="!integration.apiKey">
    <h3 style="margin-top: 20px">To add zapier</h3>
    <p>Navigate to your zapier page...</p>
    <p>
      In api key form insert this api key
      <span class="scopes">{{operationConfigId}}</span>
    </p>
  </div>
</div>