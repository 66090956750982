import { Injectable } from "@angular/core";
import { ConnectionService } from "./connection.service";
import { SmartMovingIntegration } from "../../../../server/src/db/classes/notification-integration.type";
import { OperationConfigType } from "../../../../server/src/db/classes/operation-config.type";

@Injectable({
  providedIn: "root"
})
export class SmartMovingIntegrationService {
  constructor(private connectionService: ConnectionService) { }

  async manageApiKey(
    id: string,
    type: OperationConfigType,
    apiKey: string,
    createCustomerWithPhone: boolean
  ): Promise<SmartMovingIntegration> {
    return await this.connectionService.post("/operation-config/integration/smartMovingIntegration/manageCredentials", {
      id,
      type,
      accessToken: apiKey,
      createCustomerWithPhone
    });
  }
}
