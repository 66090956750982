import { Injectable } from "@angular/core";
import { ClientSession } from '../../../../server/src/db/classes-helpers/clientSession';

@Injectable({
  providedIn: "root"
})
export class CookieService {
  constructor() { }

  static setCookie(key: string, value: { [key: string]: any }, domain: string) {
    document.cookie = `${key}=${JSON.stringify(value)}; path=/; domain=${domain}; Secure; SameSite=None`;
  }

  static getCookie(key: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${key}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
    return undefined;
  }

  static deleteCookie(key: string, domain: string) {
    document.cookie = `${key}=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=None`;
  }

  static getLoginCookieHost() {
    const loginCookie = this.getCookie("login");
    if (loginCookie) {
      const loginSession = (JSON.parse(loginCookie)) as ClientSession & { host: string };
      return loginSession.host;
    } else {
      return window.location.host === "localhost:8082" ? "localhost" : window.location.hostname.split('.').slice(1).join('.');
    }
  }

}
