import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { StorageService } from "./storage.service";
import { ClientSession } from "../../../../server/src/db/classes-helpers/clientSession";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { CookieService } from './cookie.service';

export const SUPER_ADMIN_SESSION_KEY = "superAdminSession";

@Injectable({
  providedIn: "root"
})
export class SessionService {
  tempSession;

  get sessionId() {
    return StorageService.getItem("sessionId");
  }

  set session(session) {
    if (!session) {
      localStorage.clear();
      CookieService.deleteCookie("login", CookieService.getLoginCookieHost());
    } else {
      this.reportToAnalytics(session);
      this.updateChat(session);
      CookieService.setCookie("login", { ...session, host: CookieService.getLoginCookieHost() }, CookieService.getLoginCookieHost());
      StorageService.setItem("session", session);
      StorageService.setItem("sessionId", session.token);
      const savedVer = sessionStorage.getItem("version");
      sessionStorage.setItem("version", session.version.toString())
        ;
      if (savedVer && savedVer !== session.version.toString()) {
        location.reload();
      }
      this.router.navigateByUrl("/app", { replaceUrl: true });
    }
  }

  get session(): ClientSession {
    return StorageService.getItem("session");
  }

  get prevSessionSuperAdmin(): ClientSession {
    return StorageService.getItem(SUPER_ADMIN_SESSION_KEY);
  }

  constructor(
    private router: Router,
    private googleTagManagerService: GoogleTagManagerService
  ) { }

  private async reportToAnalytics(session: ClientSession) {
    const tag = {
      event: "config",
      user_id: session.id,
      email: session.email
    };
    await this.googleTagManagerService.pushTag(tag);
    tag.event = session.new ? "signup" : "login";
    await this.googleTagManagerService.pushTag(tag);
  }

  private updateChat(session: ClientSession) {
    (window as any).$crisp.push(["set", "user:email", session.email]);
  }
}
